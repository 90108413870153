import React from 'react';
import ReactDOM from 'react-dom/client';
import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';
import App from './app';
import language_de from './translations/de/language.json';
import language_en from './translations/en/language.json';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';

i18next.init({
  interpolate: { escapeValue: true },
  lng: "de",
  resources: {
    de: {
      language: language_de,
    },
    en: {
      language: language_en,
    }
  }
}
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);
