import ContactPage from './pages/contactPage/contactPage';
import HomePage from './pages/homePage/homePage';
import ImpressumPage from './pages/impressumPage/impressumPage';
import Footer from './shared/footer';
import NavBar from './shared/navBar';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className='bg-white font-customFont'>
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='/impressum' element={<ImpressumPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
