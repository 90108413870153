import { React } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

function Footer() {
    const [t] = useTranslation("language");

    return (
        <motion.div
            className='w-full h-36 mt-20 flex justify-evenly items-center bg-customSecondary text-white'
            initial={{ opacity: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            animate={{ opacity: 1 }}
        >
            <div className='flex flex-col justify-center items-center gap-2'>
                <p className='text-md text-customPrimary'>{t("footer.col1.title")}</p>
                <motion.a href='/contact' className='text-sm' whileHover={{ scale: 1.1 }}>{t("footer.col1.line1")}</motion.a>
                <motion.a href='https://www.airbnb.de/rooms/43906916?location=zeitlofs&source_impression_id=p3_1596014621_NYsmQlmv0Js%2FCE8G&guests=1&adults=1' target="_blank" rel="noreferrer" className='text-sm' whileHover={{ scale: 1.1 }}>{t("footer.col1.line2")}</motion.a>
            </div>
            <div className='flex flex-col justify-center items-center gap-2'>
                <p className='text-md text-customPrimary'>{t("footer.col2.title")}</p>
                <motion.a href='/impressum' className='text-sm' whileHover={{ scale: 1.1 }}>{t("footer.col2.line1")}</motion.a>
                <p className='text-sm text-customSecondary'>Home</p>
            </div>
        </motion.div>
    );
}

export default Footer;