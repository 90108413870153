import React from 'react';
import HouseCarousel from './components/houseCarousel';
import HouseInfo from './components/houseInfo';
import LandscapeCarousel from './components/landscapeCarousel';

function HomePage() {
    return (
        <div>
            <HouseCarousel />
            <HouseInfo />
            <LandscapeCarousel />
        </div>
    );
}

export default HomePage;