import { React, useState } from 'react';
import { motion } from 'framer-motion';
import Offcanvas from 'react-bootstrap/Offcanvas';
import './bootstrapStyles/offcanvas.css';
import { AiOutlineContacts } from 'react-icons/ai';
import { AiFillContacts } from 'react-icons/ai'
import { AiOutlineHome } from 'react-icons/ai';
import { AiTwotoneHome } from 'react-icons/ai';
import { FiMenu } from 'react-icons/fi';
import { AiOutlineClose } from 'react-icons/ai';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

function NavBar() {
    const [shouldBeFixed, setShouldBeFixed] = useState(false);

    const checkScroll = () => {
        if (window.scrollY >= 350) setShouldBeFixed(true);
        else setShouldBeFixed(false);
    }

    window.addEventListener("scroll", checkScroll);

    return (
        <>
            {shouldBeFixed && <Placeholder />}
            <motion.div
                className={`
                    ${!shouldBeFixed
                        ? 'w-full h-36 flex items-center justify-between xs:pl-2 md:pl-5 xs:pr-10 md:pr-20 bg-customNavBar shadow-md'
                        : 'fixed top-0 right-0 z-10 bg-customNavBar xs:px-4 md:px-5 xs:py-4 md:py-3 transition-all duration-1000 ease-in-out'
                    }`}
                initial={{ opacity: 0 }}
                transition={{ duration: 1, delay: 0.5 }}
                animate={{ opacity: 1 }}
            >
                <Logo />
                <div className='xs:hidden md:block'><MenuItems /></div>
                <div className='xs:block md:hidden'><MobileMenu /></div>
            </motion.div >
        </>
    );
}

function Placeholder() {
    return (
        <div className='w-full h-36'></div>
    );
}

function Logo() {
    const [shouldBeFixed, setShouldBeFixed] = useState(false);

    const checkScroll = () => {
        if (window.scrollY >= 300) setShouldBeFixed(true);
        else setShouldBeFixed(false);
    }

    window.addEventListener("scroll", checkScroll);

    return (
        <div
            className={`xs:w-logoSmall sm:w-logoMedium md:w-logo h-full flex justify-center items-center 
                ${shouldBeFixed ? 'hidden' : undefined}`}>
            <a href='/'><img src='assets/images/logo.png' alt='logo' className='max-w-full max-h-full'></img></a>
        </div >
    );
}

function MenuItems() {
    return (
        <div className='flex gap-6 justify-center items-center'>
            <MenuItem name={"home"} />
            <MenuItem name={"contact"} />
            <LanguagePicker />
        </div>
    );
}

function MenuItem({ name }) {
    const [isHovering, setIsHovering] = useState(false);
    return (
        <motion.div
            className='text-3xl text-customSecondary p-1'
            onHoverStart={() => setIsHovering(true)}
            onHoverEnd={() => setIsHovering(false)}
            whileHover={{ scale: 1.1 }}
        >
            {name === "home" && (!isHovering ? <a href='/'><AiOutlineHome /></a> : <a href='/'><AiTwotoneHome /></a>)}
            {name === "contact" && (!isHovering ? <a href='/contact'><AiOutlineContacts /></a> : <a href='/contact'><AiFillContacts /></a>)}
        </motion.div>
    );
}

function LanguagePicker() {
    const [gerIsActive, setGerIsActive] = useState(true);

    const handleLanguageChange = (lang) => {
        if (lang === "de")
            setGerIsActive(true);
        else
            setGerIsActive(false);
        i18next.changeLanguage(lang);
    }

    return (
        <div className='flex gap-1'>
            <button onClick={() => handleLanguageChange("de")} className={`${gerIsActive ? 'text-customSecondary' : 'text-customPrimary'}`}>DE</button>
            <p>|</p>
            <button onClick={() => handleLanguageChange("en")} className={`${gerIsActive ? 'text-customPrimary' : 'text-customSecondary'}`}>EN</button>
        </div >
    );
}

function MobileMenu() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [t] = useTranslation("language");

    return (
        <div>
            <motion.button onClick={handleShow} className='text-3xl text-customSecondary' whileHover={{ scale: 1.1 }}>
                <FiMenu />
            </motion.button>

            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header>
                    <div className='w-full flex justify-end items-center'>
                        <motion.button onClick={handleClose} whileHover={{ scale: 1.1 }}>
                            <AiOutlineClose className='text-customSecondary text-2xl' />
                        </motion.button>
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='w-full flex flex-col gap-4'>
                        <div className='flex justify-start items-center gap-3'>
                            <motion.a href='/' whileHover={{ scale: 1.1 }}><AiOutlineHome className='text-2xl text-customSecondary' /></motion.a>
                            <motion.a href='/' className=' text-customSecondary text-xl' whileHover={{ scale: 1.1 }}>{t("navbar.item1")}</motion.a>
                        </div>
                        <div className='flex justify-start items-center gap-3'>
                            <motion.a href='contact' whileHover={{ scale: 1.1 }}><AiOutlineContacts className='text-2xl text-customSecondary' /></motion.a>
                            <motion.a href='/contact' className='text-customSecondary text-xl' whileHover={{ scale: 1.1 }}>{t("navbar.item2")}</motion.a>
                        </div>
                        <div className='flex justify-start items-center gap-3'>
                            <LanguagePicker />
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas >
        </div>
    );
}


export default NavBar;