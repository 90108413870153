import { React } from 'react';
import ContactInfo from './components/contactInfo';
import LocationInfo from './components/locationInfo';

function ContactPage() {
    return (
        <div>
            <ContactInfo />
            <LocationInfo />
        </div>
    );
}

export default ContactPage;