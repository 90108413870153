import { React } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

function ImpressumPage() {
    return (
        <motion.div
            initial={{ y: 100, opacity: 0 }}
            transition={{ delay: 0.5, duration: 1 }}
            animate={{ y: 0, opacity: 1 }}
            className=' bg-customBackground m-10 p-10'
        >
            <ContactSection />
            <DataProtectionSection />
            <ContentSection />
            <CreatorSection />
        </motion.div >
    );
}

function ContactSection() {
    const [t] = useTranslation("language");
    return (
        <div className='flex justify-center'>
            <div className='w-96'>
                <h2 className='text-2xl mb-2 text-start'>{t("impressum.section1.title")}</h2>
                <p>Gabriele Norberg</p>
                <p>Kapellenstraße 14</p>
                <p>97799 Zeitlofs</p>
                <p>{t("impressum.section1.line1")}</p>
                <p>Tel.: +49 173 79 70 290</p>
                <p className='xs:text-sm md:text-base '>E-Mail: Gabrielenorberg112@gmail.com</p>
                <hr className='mt-3' />
            </div>
        </div>
    );
}

function DataProtectionSection() {
    const [t] = useTranslation("language");
    return (
        <div className='flex justify-center mt-3'>
            <div className='w-96'>
                <h2 className='text-2xl mb-2 text-start'>{t("impressum.section2.title")}</h2>
                <p>{t("impressum.section2.line1")}</p>
                <p>{t("impressum.section2.line2")}</p>
            </div>
        </div>
    );
}

function ContentSection() {
    const [t] = useTranslation("language");
    return (
        <div className='flex justify-center mt-3'>
            <div className='w-96'>
                <h2 className='text-2xl mb-2 text-start'>{t("impressum.section3.title")}</h2>
                <p>{t("impressum.section3.line1")}</p>
                <ul>
                    <li>{t("impressum.section3.line2")}</li>
                    <li>{t("impressum.section3.line3")}</li>
                    <li>{t("impressum.section3.line4")}</li>
                </ul>
                <hr className='mt-3' />
            </div>
        </div>
    );
}

function CreatorSection() {
    const [t] = useTranslation("language");
    return (
        <div className='flex justify-center mt-3'>
            <div className='w-96'>
                <h2 className='text-2xl mb-2 text-start'>{t("impressum.section4.title")}</h2>
                <p>{t("impressum.section4.line1")}</p>
            </div>
        </div>
    );
}

export default ImpressumPage;