import { React } from 'react';
import { motion } from 'framer-motion';
import { useInView } from "react-intersection-observer";
import { IoMdContact } from 'react-icons/io';

function ContactInfo() {
    return (
        <motion.div
            className='w-11/12 mx-auto flex xs:flex-col md:flex-row mt-16 bg-customBackground xs:pb-10 md:p-3'
            initial={{ opacity: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            animate={{ opacity: 1 }}
        >
            <ContactImage />
            <ContactLines />
        </motion.div >
    );
}

function ContactImage() {
    const [ref, inView] = useInView({ triggerOnce: true });

    return (
        <motion.div
            className='xs:w-full md:w-3/5 p-5 flex justify-center items-center'
            ref={ref}
            initial={{ x: -100, opacity: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            animate={inView ? { x: 0, opacity: 1 } : undefined}
        >
            <img src='assets/images/contact.png' alt='contact' className='max-w-full max-h-full shadow-2xl'></img>
        </motion.div>
    );
}

function ContactLines() {
    const [ref, inView] = useInView({ triggerOnce: true });

    return (
        <motion.div
            className='xs:w-full md:w-2/5 flex flex-col justify-center items-center gap-2 text-customSecondary'
            ref={ref}
            initial={{ x: 100, opacity: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            animate={inView ? { x: 0, opacity: 1 } : undefined}
        >
            <IoMdContact className='xs:text-3xl sm:text-4xl md:text-5xl mb-3' />
            <p>Gabriele Norberg</p>
            <p>Gabrielenorberg112@gmail.com</p>
            <p>+49 173 79 70 290</p>
            <a href='https://www.airbnb.de/rooms/43906916?location=zeitlofs&source_impression_id=p3_1596014621_NYsmQlmv0Js%2FCE8G&guests=1&adults=1' target="_blank" rel="noreferrer" className='underline'>AirBnB</a>
        </motion.div>
    );
}

export default ContactInfo;