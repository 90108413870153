import { React } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { IoIosArrowForward } from 'react-icons/io';
import { Carousel } from 'react-bootstrap';
import { motion } from 'framer-motion';

function HouseCarousel() {
    const videoFormatPath = "assets/images/house/16:9/";
    const mobileFormatPath = "assets/images/house/mobile/";
    var path = "";

    if (window.innerWidth < 768) path = mobileFormatPath;
    else path = videoFormatPath;

    return (
        <motion.div
            initial={{ y: 100, opacity: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            animate={{ y: 0, opacity: 1 }}
        >
            <Carousel
                className='w-full shadow-2xl'
                prevIcon={<IoIosArrowBack className='text-8xl text-white' />}
                nextIcon={<IoIosArrowForward className='text-8xl text-white' />}
            >
                <Carousel.Item>
                    <img src={`${path}1.jpg`} alt='slide 1'></img>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={`${path}2.jpg`} alt='slide 2'></img>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={`${path}3.jpg`} alt='slide 3'></img>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={`${path}4.jpg`} alt='slide 4'></img>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={`${path}5.jpg`} alt='slide 5'></img>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={`${path}6.jpg`} alt='slide 6'></img>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={`${path}7.jpg`} alt='slide 7'></img>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={`${path}8.jpg`} alt='slide 8'></img>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={`${path}9.jpg`} alt='slide 9'></img>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={`${path}10.jpg`} alt='slide 10'></img>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={`${path}11.jpg`} alt='slide 11'></img>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={`${path}12.jpg`} alt='slide 12'></img>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={`${path}13.jpg`} alt='slide 13'></img>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={`${path}14.jpg`} alt='slide 14'></img>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={`${path}15.jpg`} alt='slide 15'></img>
                </Carousel.Item>
            </Carousel>
        </motion.div>
    );
}

export default HouseCarousel;