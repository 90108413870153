import { React } from 'react';
import { FaMapLocationDot } from 'react-icons/fa6';
import { motion } from 'framer-motion';
import { useInView } from "react-intersection-observer";
import { useTranslation } from 'react-i18next';

function LocationInfo() {
    return (
        <motion.div
            className='w-11/12 mx-auto flex xs:flex-col-reverse md:flex-row mt-24 bg-customBackground xs:pb-10 md:p-3'
            initial={{ opacity: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            animate={{ opacity: 1 }}
        >
            <AddressLines />
            <AddressMap />
        </motion.div>
    );
}

function AddressLines() {
    const [ref, inView] = useInView({ triggerOnce: true });
    const [t] = useTranslation("language");

    return (
        <motion.div
            className='xs:w-full md:w-2/5 flex flex-col justify-center items-center gap-2 text-customSecondary'
            ref={ref}
            initial={{ x: -100, opacity: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            animate={inView ? { x: 0, opacity: 1 } : undefined}
        >
            <FaMapLocationDot className='xs:text-3xl sm:text-5xl mb-3' />
            <p>97799 Zeitlofs</p>
            <p>Oberdorferweg 16</p>
            <p>{t("locationInfo.country")}</p>
        </motion.div>
    );
}

function AddressMap() {
    const [ref, inView] = useInView({ triggerOnce: true });

    return (
        <motion.div
            ref={ref}
            initial={{ x: 100, opacity: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            animate={inView ? { x: 0, opacity: 1 } : undefined}
            className=' xs:w-full md:w-3/5 flex justify-center items-center p-5'
        >
            <div className='w-full h-full aspect-video'>
                <iframe
                    title='address-map'
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4291.67455423944!2d9.678311964587664!3d50.23880232035692!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a2d903dfdc65d1%3A0xcd7d86f2018735be!2sG%C3%A4stehaus%20im%20Garten!5e0!3m2!1sde!2sat!4v1689769816129!5m2!1sde!2sat"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    width="100%"
                    height="100%"
                    className='shadow-2xl'
                ></iframe>
            </div>
        </motion.div>
    );
}


export default LocationInfo;