import { React } from 'react';
import { BsFillHouseHeartFill } from 'react-icons/bs';
import { Accordion } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { useInView } from "react-intersection-observer";
import './bootstrapStyles/bootstrapAccordion.css';
import { useTranslation } from 'react-i18next';

function HouseInfo() {
    return (
        <motion.div
            className='w-10/12 mx-auto xs:h-full md:h-houseinfo mt-20 mb-20 flex xs:gap-0 md:gap-10 lg:gap-28 bg-customBackground'
            initial={{ opacity: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            animate={{ opacity: 1 }}
        >
            <div className='xs:w-0 md:w-1/2 flex justify-end items-center'>
                <div className='xs:hidden md:block'><HouseItem /></div>
            </div>
            <div className='xs:w-full md:w-1/2 flex xs:justify-center md:justify-start items-center'>
                <FloorItems />
            </div>
        </motion.div>
    );
}

function HouseItem() {
    const [ref, inView] = useInView();

    return (
        <motion.div
            ref={ref}
            initial={{ x: -50, opacity: 0 }}
            transition={{ duration: 0.5 }}
            animate={inView ? { x: 0, opacity: 1 } : undefined}
        >
            <BsFillHouseHeartFill className='md:text-houseiconMedium lg:text-houseicon text-customSecondary' />
        </motion.div>
    );
}

function FloorItems() {
    const [ref, inView] = useInView();
    const [t] = useTranslation("language");

    return (
        <Accordion flush className='xs:w-96 xs:p-5 md:w-68 lg:w-80 xl:w-96'>
            <motion.div
                ref={ref}
                initial={{ x: 50, opacity: 0 }}
                transition={{ duration: 0.5 }}
                animate={inView ? { x: 0, opacity: 1 } : undefined}
            >
                <Accordion.Item eventKey="0">
                    <Accordion.Header
                        className='bg-customSecondary font-normal'
                        style={{
                            '--bs-accordion-active-bg': 'rgb(61, 64, 51)',
                            '--bs-accordion-active-color': 'white',
                        }}
                    >
                        <p className='text-white text-md'>{t("houseInfo.element1.title")}</p>
                    </Accordion.Header>
                    <Accordion.Body className='bg-customPrimary text-md'>
                        <div className='flex flex-col md:gap-1 lg:gap-2 text-white'>
                            <p>{t("houseInfo.element1.line1")}</p>
                            <hr />
                            <p>{t("houseInfo.element1.line2")}</p>
                            <hr />
                            <p>{t("houseInfo.element1.line3")}</p>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </motion.div>
            <motion.div
                initial={{ x: 50, opacity: 0 }}
                transition={{ duration: 0.5 }}
                animate={inView ? { x: 0, opacity: 1 } : undefined}
            >
                <Accordion.Item eventKey="1" className='mt-4'>
                    <Accordion.Header
                        className='bg-customSecondary font-normal'
                        style={{
                            '--bs-accordion-active-bg': 'rgb(61, 64, 51)',
                            '--bs-accordion-active-color': 'white',
                        }}
                    >
                        <p className='text-white text-md'>{t("houseInfo.element2.title")}</p>
                    </Accordion.Header>
                    <Accordion.Body className='bg-customPrimary text-md'>
                        <div className='flex flex-col md: gap-1 lg:gap-2 text-white'>
                            <p>{t("houseInfo.element2.line1")}</p>
                            <hr />
                            <p>{t("houseInfo.element2.line2")}</p>
                            <hr />
                            <p>{t("houseInfo.element2.line3")}</p>
                            <hr />
                            <p>{t("houseInfo.element2.line4")}</p>
                            <hr />
                            <p>{t("houseInfo.element2.line5")}</p>
                            <hr />
                            <p>{t("houseInfo.element2.line6")}</p>
                            <hr />
                            <p>{t("houseInfo.element2.line7")}</p>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </motion.div>
            <motion.div
                initial={{ x: 50, opacity: 0 }}
                transition={{ duration: 0.5 }}
                animate={inView ? { x: 0, opacity: 1 } : undefined}
            >
                <Accordion.Item eventKey="2" className='mt-4'>
                    <Accordion.Header
                        className='bg-customSecondary font-normal'
                        style={{
                            '--bs-accordion-active-bg': 'rgb(61, 64, 51)',
                            '--bs-accordion-active-color': 'white',
                        }}
                    >
                        <p className='text-white text-md'>{t("houseInfo.element3.title")}</p>
                    </Accordion.Header>
                    <Accordion.Body className='bg-customPrimary text-md'>
                        <div className='flex flex-col md:gap-1 lg:gap-2 text-white'>
                            <p>{t("houseInfo.element3.line1")}</p>
                            <hr />
                            <p>{t("houseInfo.element3.line2")}</p>
                            <hr />
                            <p>{t("houseInfo.element3.line3")}</p>
                            <hr />
                            <p>{t("houseInfo.element3.line4")}</p>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </motion.div>
        </Accordion >
    );
}

export default HouseInfo;